html,
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media screen and (display-mode: standalone) {
  body {
    min-height: 100dvh;
  }
}

div[role='group'][tabindex] {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#root {
  height: 100%;
}
